@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-toastify/dist/ReactToastify.css';

@font-face {
  font-family: 'SUIT';
  font-weight: 100;
  src: url('./fonts/SUIT-Thin.woff2') format('woff2');
}
@font-face {
  font-family: 'SUIT';
  font-weight: 200;
  src: url('./fonts/SUIT-ExtraLight.woff2') format('woff2');
}
@font-face {
  font-family: 'SUIT';
  font-weight: 300;
  src: url('./fonts/SUIT-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'SUIT';
  font-weight: 400;
  src: url('./fonts/SUIT-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'SUIT';
  font-weight: 500;
  src: url('./fonts/SUIT-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'SUIT';
  font-weight: 600;
  src: url('./fonts/SUIT-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'SUIT';
  font-weight: 700;
  src: url('./fonts/SUIT-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'SUIT';
  font-weight: 800;
  src: url('./fonts/SUIT-ExtraBold.woff2') format('woff2');
}
@font-face {
  font-family: 'SUIT';
  font-weight: 900;
  src: url('./fonts/SUIT-Heavy.woff2') format('woff2');
}

@font-face {
  font-family: 'agro';
  font-weight: 700;
  src: url('./fonts/SB-어그로OTF-B.woff2') format('woff2');
}

@font-face {
  font-family: 'agro';
  font-weight: 500;
  src: url('./fonts/SB-어그로OTF-M.woff2') format('woff2');
}

@font-face {
  font-family: 'agro';
  font-weight: 300;
  src: url('./fonts/SB-어그로OTF-L.woff2') format('woff2');
}

@font-face {
  font-family: 'samlip';
  font-weight: 500;
  src: url('./fonts/SDSamliphopangcheBasic.woff2') format('woff2');
}

@layer base {
  html,
  body {
    font-family: 'SUIT';
  }

  body,
  #root {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

@layer components {
  .flex-center {
    @apply flex justify-center items-center;
  }

  .transform-center {
    @apply absolute w-4/5 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
  }

  .flex-center-x {
    @apply flex justify-center;
  }
}

:root {
  /* Neutral */
  --grey-100: #0a0a0a;
  --grey-80: #484848;
  --grey-60: #7d7d7d;
  --grey-40: #a0a0a0;
  --grey-20: #d0d0d0;
  --grey-10: #cecece;
  --grey-00: #fbfbfb;
  /* Text */
  --text-active: #0d0d0d;
  --text-darken: #303030;
  --text-default: #565656;
  --text-muted: #929292;
  --text-disabled: #c5c5c5;
  --text-lighten: #fbfbfb;
  /* background */
  --bg-surface: #ffffff;
  --bg-on-surface: #f0f0f0;
  --bg-divider: #ededed;
  --bg-input: #fafafa;
  --bg-input-border-default: #e2e2e2;
  --bg-input-border-focus: #1b1b1c;
  /* etc */
  --border-selected: #30b5ff;
}
@media screen {
}
