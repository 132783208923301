.input {
  width: 100%;
  background-color: var(--bg-on-surface);
  padding: 12px 16px;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  box-sizing: border-box;
  margin: 12px 0;
}
.buttonGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.colorButton,
.selectedColorButton,
.sizeButton,
.selectedSizeButton {
  width: 32px;
  aspect-ratio: 1/1;
  border: 1px solid var(--grey-10);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.colorButton > svg {
  display: none;
}
.selectedColorButton {
  border: 2px solid var(--border-selected);
}
.selectedColorButton svg,
.closeBtn {
  display: flex;
}

.selectedSizeButton {
  background-color: var(--grey-100);
}

.selectedSizeButton svg {
  color: white;
}

@media not screen and (max-width: 667px) {
  .colorButton,
  .selectedColorButton,
  .sizeButton,
  .selectedSizeButton {
    width: 48px;
  }
  /* .input {
    font-size: 16px;
  } */
}
